import React from 'react';
import { Route } from 'react-router-dom';

const Home = React.lazy(() => import('pages/studentSide/Home/Home'));

const StudentNews = React.lazy(
  () => import('pages/studentSide/academy/StudentNews/StudentNews'),
);

const SEtc = React.lazy(() => import('pages/studentSide/academy/SEtc/SEtc'));

const StudentPostView = React.lazy(
  () => import('pages/studentSide/academy/StudentPostView/StudentPostView'),
);
const BillList = React.lazy(
  () => import('pages/studentSide/academy/bill/BillList/BillList'),
);
const BillDetail = React.lazy(
  () => import('pages/studentSide/academy/bill/BillDetail/BillDetail'),
);
const EPayEnd = React.lazy(
  () => import('pages/studentSide/academy/bill/EPayEnd/EPayEnd'),
);

const CommonTeacherSelect = React.lazy(
  () =>
    import('pages/studentSide/common/CommonTeacherSelect/CommonTeacherSelect'),
);

// attendance
const StudentAttRecordList = React.lazy(
  () =>
    import(
      'pages/studentSide/academy/attendance/StudentAttRecordList/StudentAttRecordList'
    ),
);

// homework
const StudentHomeworkList = React.lazy(
  () =>
    import(
      'pages/studentSide/academy/homework/StudentHomeworkList/StudentHomeworkList'
    ),
);
const StudentHomeworkDetail = React.lazy(
  () =>
    import(
      'pages/studentSide/academy/homework/StudentHomeworkDetail/StudentHomeworkDetail'
    ),
);

// chat
const StudentChatRoomList = React.lazy(
  () =>
    import(
      'pages/studentSide/academy/chat/StudentChatRoomList/StudentChatRoomList'
    ),
);

const StudentChatRoomContent = React.lazy(
  () =>
    import(
      'pages/studentSide/academy/chat/StudentChatRoomContent/StudentChatRoomContent'
    ),
);

// 푸시 관련
const PushList = React.lazy(
  () => import('pages/studentSide/academy/push/PushList/PushList'),
);

const RouteListStudentSide = (
  <Route path="studentSide/">
    {/* 학생측 화면 시작 */}
    <Route path="home" element={<Home />} />
    <Route path="academy/:academyId/news" element={<StudentNews />} />
    <Route path="academy/:academyId/etc" element={<SEtc />} />

    <Route
      path="academy/:academyId/common/teacherSelect"
      element={<CommonTeacherSelect />}
    />

    <Route path="academy/:academyId/bill/">
      <Route path="list" element={<BillList />} />
      <Route path="detail/:billGroupId/:studentId" element={<BillDetail />} />
      <Route path="epayEnd" element={<EPayEnd />} />
    </Route>

    <Route path="academy/:academyId/post/">
      <Route
        path="notePost/:postId/:rid"
        element={<StudentPostView postType="notePost" />}
      />
      <Route
        path="boardPost/:postId"
        element={<StudentPostView postType="boardPost" />}
      />
    </Route>

    <Route path="academy/:academyId/attendance/">
      <Route path="list" element={<StudentAttRecordList />} />
    </Route>

    <Route path="academy/:academyId/homework/">
      <Route path="list" element={<StudentHomeworkList />} />
      <Route
        path="detail/:homeworkGroupId/:studentId"
        element={<StudentHomeworkDetail />}
      />
    </Route>

    <Route path="academy/:academyId/chat">
      <Route path="" element={<StudentChatRoomList />} />
      <Route path="room" element={<StudentChatRoomContent />} />
    </Route>

    <Route path="academy/:academyId/push">
      <Route path="list" element={<PushList />} />
    </Route>
  </Route>
);

export default RouteListStudentSide;
