import React from 'react';
import { Route } from 'react-router-dom';

// bill
const BillDetail = React.lazy(
  () => import('pages/teacherSide/bill/BillDetail/BillDetail'),
);
const BillGroupSimpleAdd = React.lazy(
  () => import('pages/teacherSide/bill/BillGroupSimpleAdd/BillGroupSimpleAdd'),
);

const BillGroupList = React.lazy(
  () => import('pages/teacherSide/bill/BillGroupList/BillGroupList'),
);

const BillGroupDetail = React.lazy(
  () => import('pages/teacherSide/bill/BillGroupDetail/BillGroupDetail'),
);

const CashBillInfo = React.lazy(
  () => import('pages/teacherSide/cashBill/CashBillInfo/CashBillInfo'),
);
const CashBillIssue = React.lazy(
  () => import('pages/teacherSide/cashBill/CashBillIssue/CashBillIssue'),
);
const CashBillIssueWOBill = React.lazy(
  () =>
    import(
      'pages/teacherSide/cashBill/CashBillIssueWOBill/CashBillIssueWOBill'
    ),
);

const CashBillList = React.lazy(
  () => import('pages/teacherSide/cashBill/CashBillList/CashBillList'),
);
const RouteListTSBill = (
  <Route path="/teacherSide/bill/">
    <Route path="detail/:billGroupId/:studentId" element={<BillDetail />} />
    <Route path="group/">
      <Route path="list" element={<BillGroupList />} />
      <Route path="simpleAdd" element={<BillGroupSimpleAdd />} />
      <Route path="detail/:billGroupId" element={<BillGroupDetail />} />
    </Route>

    <Route path="cashBill/">
      <Route path="info" element={<CashBillInfo />} />
      <Route path="issue" element={<CashBillIssue />} />

      <Route path="issueWOBill" element={<CashBillIssueWOBill />} />
      <Route path="list" element={<CashBillList />} />
    </Route>
  </Route>
);

export default RouteListTSBill;
