import AccessTokenManager from 'utils/AccessTokenManager';

function goToWelcomePage() {
  const url = '/user/signIn';
  if (window.self !== window.top) {
    // This code is running inside an iframe
    window.location.href = url;
  } else {
    // This code is running in a top-level window
    self.location.href = url;
  }
}

export function GlobalAuthRouter() {
  AccessTokenManager.accessTokenState.addWatcher((newState) => {
    if (newState === 'ERROR') {
      goToWelcomePage();
    }
  });

  return null;
}
