/* eslint-disable @typescript-eslint/no-explicit-any */
// 이 파일은 asset 내의 interface.js 를
// typescript / react 에서 사용하기 좋게 감싸거나
// helper function 을 제공한다.

// base64

import { BACK_AFTER_EDITING } from 'constants/frontend/shared/values';
import { ActionSheetActionT, DeviceInfoKeyT } from 'types/frontend/inapp';
import { NativeDataKeyT } from 'types/frontend/shared';
import { TokenObjT } from 'types/shared/core/Token';

type ButtonPosition = 'left' | 'rightMain' | 'rightSub';

type IconName =
  | 'back'
  | 'chat'
  | 'close'
  | 'search'
  | 'settings'
  | 'notifications'
  | 'more';

// | 'write'
// | 'news'
// | 'note'
// | 'schedule'
// | 'more';

//
// main
function requestNativeFeature(
  obj: any,
  resultHandler?: any,
  shouldReturn?: boolean,
) {
  // console.log('interfaceBridge - requestNativeFeature', obj);
  return (window as any).requestNativeFeature(obj, resultHandler, shouldReturn);
}

//
//
// navigation bar
function setTitle(title: string) {
  requestNativeFeature({
    action: 'title',
    title,
  });
}

function setButtonText(position: 'rightMain' | 'rightSub', title?: string) {
  requestNativeFeature({
    action: 'button',
    position,
    title,
  });
}

function setButtonIcon(position: ButtonPosition, icon?: IconName) {
  requestNativeFeature({
    action: 'button',
    position,
    icon,
  });
}

function showNavigationBar(isVisible: boolean, withAnimation: boolean) {
  (window as any).showNavigationBar(isVisible, withAnimation);
}

//
//
// navigation
function back(result?: string) {
  console.log('interfaceBridge back, result=', result);
  requestNativeFeature({
    action: 'back',
    result,
  });
}

function backAfterEditing() {
  requestNativeFeature({
    action: 'back',
    result: BACK_AFTER_EDITING,
  });
}

function nav(
  initialTitle: string,
  url: string,
  target: 'current' | 'cover' | 'external',
  needResult = false,
  resultHandler: ((resultObj: any) => void) | undefined = undefined,
) {
  interfaceBridge.requestNativeFeature(
    {
      action: 'nav',
      initialTitle,
      url,
      target,
      needResult,
    },
    resultHandler,
    false,
  );
}

// 채팅에서 왼쪽에 창 띄우기용
function navOnLeftSide(
  initialTitle: string,
  url: string,
  target: 'current' | 'cover' | 'external',
  needResult = false,
  resultHandler: ((resultObj: any) => void) | undefined = undefined,
) {
  interfaceBridge.requestNativeFeature(
    {
      action: 'nav',
      initialTitle,
      url,
      target,
      needResult,
      isOnLeftSide: true,
    },
    resultHandler,
    false,
  );
}

//
// 학생측 홈 화면 띄우기
// nav와 다르게 화면 스택을 모두 없앰
function navStudentSideHome() {
  interfaceBridge.requestNativeFeature({
    action: 'navStudentSideHome',
  });
}

//
// 학생측 탭있는 화면 띄우기
// 한 학생은 여러 학원에 다니므로 academyId가 필요
function navStudentSide(academyId: number, academyName: string) {
  interfaceBridge.requestNativeFeature({
    action: 'navStudentSide',
    academyId,
    academyName,
  });
}
//
// 교사측 탭있는 화면 띄우기
function navTeacherSide() {
  interfaceBridge.requestNativeFeature({
    action: 'navTeacherSide',
  });
}

//
//
// ui snippets
function customAlert(title: string, message: string, closeBtn: string): void {
  (window as any).customAlert(title, message, closeBtn);
}

function customConfirm(
  title: string,
  message: string,
  confirmBtn: string,
  cancelBtn: string,
  isDestructive?: boolean,
): boolean {
  return (window as any).customConfirm(
    title,
    message,
    confirmBtn,
    cancelBtn,
    isDestructive,
  );
}

function setBtnEventHandler(
  handler: Partial<Record<ButtonPosition, () => void>>,
): void {
  if (handler.left) (window as any).btnEventHandlerLeft = handler.left;
  if (handler.rightMain)
    (window as any).btnEventHandlerRightMain = handler.rightMain;
  if (handler.rightSub)
    (window as any).btnEventHandlerRightSub = handler.rightSub;
}

function setNativeEventHandler(
  handler: (eventType: string, encodedEvent: string) => void,
) {
  (window as any).nativeEventHandler = handler;
  // console.log(
  //   'window.nativeEventHandler = ',
  //   (window as any).nativeEventHandler,
  // );
}

//
//
// token
// saveToken이후 할 작업이 있으므로 동기적으로 return 필요
// null 을 주면 삭제된다.
function saveToken(userId: number, tokenObj: TokenObjT | null): string {
  const result = interfaceBridge.requestNativeFeature(
    {
      action: 'saveToken',
      userId,
      tokenObj,
    },
    null,
    true,
  );

  console.log('save token result = ', result);
  return 'success';
}

function loadToken(userId: number): TokenObjT | null {
  const result = interfaceBridge.requestNativeFeature(
    {
      action: 'loadToken',
      userId,
    },
    null,
    true,
  );

  if (!result) return null;

  return JSON.parse(result) as TokenObjT | null;
}

function setData(key: NativeDataKeyT, dataStr: string): void {
  interfaceBridge.requestNativeFeature(
    {
      action: 'setData',
      key,
      valueStr: dataStr,
    },
    null,
    true,
  );
  // console.log('setData', dataStr);
}

function getData(key: NativeDataKeyT): string {
  const result = interfaceBridge.requestNativeFeature(
    {
      action: 'getData',
      key,
    },
    null,
    true,
  );
  // console.log('getData', result);
  return result as string;
}

function deviceInfo(key: DeviceInfoKeyT): string {
  const result = interfaceBridge.requestNativeFeature(
    {
      action: 'deviceInfo',
      key,
    },
    null,
    true,
  );
  // console.log('deviceInfo', result);
  return result as string;
}

function getUserIds(): number[] {
  const result = interfaceBridge.requestNativeFeature(
    {
      action: 'getUserIds',
    },
    null,
    true,
  );

  return JSON.parse(result) as number[];
}

function getAppVersionName(): string {
  try {
    const result = interfaceBridge.requestNativeFeature(
      {
        action: 'getAppVersion',
      },
      null,
      true,
    );

    const resultObj = JSON.parse(result);
    return resultObj.name || '';
  } catch (e) {
    return '';
  }
}

function getAppBuildNumber(): number {
  try {
    const result = interfaceBridge.requestNativeFeature(
      {
        action: 'getAppVersion',
      },
      null,
      true,
    );

    console.log('getAppVersion result=', result);

    const resultObj = JSON.parse(result);
    return resultObj.build || 0;
  } catch (error) {
    return 0;
  }
}

function isTestEnvironment(): boolean {
  return !!(window as any).isTestEnvironment;
}

// part 7 - 이 함수는 토큰 관련이지만 비동기적, 리턴 필요 없음
function sendPushTokenToServer() {
  interfaceBridge.requestNativeFeature({
    action: 'sendPushTokenToServer',
  });
}

//
//
//

function uploadPostImage(
  academyId: number,
  postIdentifier: string,
  maxCount: number,
) {
  // console.log('interfaceBridge - uploadPostImage');
  interfaceBridge.requestNativeFeature({
    action: 'uploadPostImage',
    academyId,
    postIdentifier,
    maxCount,
  });
}

function uploadPostVideo(
  academyId: number,
  postIdentifier: string,
  maxCount: number,
) {
  // console.log('interfaceBridge - uploadPostImage');
  interfaceBridge.requestNativeFeature({
    action: 'uploadPostVideo',
    academyId,
    postIdentifier,
    maxCount,
  });
}

function uploadPostAttachment(
  academyId: number,
  postIdentifier: string,
  maxCount: number,
) {
  interfaceBridge.requestNativeFeature({
    action: 'uploadPostAttachment',
    academyId,
    postIdentifier,
    maxCount,
  });
}

function cancelUpload(fileKey: string) {
  interfaceBridge.requestNativeFeature({
    action: 'cancelUpload',
    fileKey,
  });
}

function uploadTempImage(
  academyId: number,
  type: 'studentProfile' | 'userProfile' | 'corpReg',
  maxWidth: number,
  ratioW: number, // 0 이면 비율 제한 없음 - crop 창 뜨지 않음
  ratioH: number,
  resultHandler: (resultObj: any) => void,
) {
  interfaceBridge.requestNativeFeature(
    {
      action: 'uploadTempImage',
      academyId,
      type,
      maxWidth,
      ratioW,
      ratioH,
    },
    resultHandler,
    false,
  );
}

function downloadFile(
  url: string,
  fileName: string,
  type: 'image' | 'video' | 'etc',
) {
  interfaceBridge.requestNativeFeature({
    action: 'downloadFile',
    url,
    fileName,
    type,
  });
}

function toast(
  category: 'info' | 'warning' | 'error',
  title: string,
  message: string,
) {
  interfaceBridge.requestNativeFeature({
    action: 'toast',
    category: category, // info, warning, error
    title: title,
    message: message,
  });
}

function actionSheet(
  title: string,
  message: string | undefined, // undefined를 전해서 생략 가능
  actions: ActionSheetActionT[],
  resultHandler: (value: string) => void,
) {
  interfaceBridge.requestNativeFeature(
    {
      action: 'actionSheet',
      title: title,
      message: message,
      actions: actions,
    },
    resultHandler,
  );
}

function translate(
  text: string,
  direction: 'korToEng' | 'engToKor',
  resultHandler: (value: string) => void,
) {
  interfaceBridge.requestNativeFeature(
    {
      action: 'translate',
      text,
      direction,
    },
    resultHandler,
  );
}

const interfaceBridge = {
  requestNativeFeature,
  // part 1 - 네비게이션 관련
  back,
  backAfterEditing,
  nav,
  navOnLeftSide,
  navStudentSideHome,
  navStudentSide,
  navTeacherSide,
  // part 2 - ui snippets
  toast,
  actionSheet,
  // part 3 - navigationbar 설정관련
  setTitle,
  setBtnEventHandler,
  showNavigationBar,
  setButtonText,
  setButtonIcon,
  setNativeEventHandler,
  // part 4 - javascript 기본 기능 확장
  customAlert,
  customConfirm,
  // part 5 perference
  saveToken,
  loadToken,
  getUserIds,
  getData,
  setData,
  deviceInfo,
  getAppVersionName,
  getAppBuildNumber,
  isTestEnvironment,
  // part 6 - native 화면 필요한 부가기능
  uploadPostImage,
  uploadPostVideo,
  uploadPostAttachment,
  uploadTempImage,
  cancelUpload,
  downloadFile,
  // part 7 - 토큰 서버에 보내기
  sendPushTokenToServer,
  // part 8 - native 자원 활용 기능
  translate,
};

export default interfaceBridge;
