import React from 'react';
import { Route } from 'react-router-dom';

//
// 4) 교사화면 탭
//
const DoSomething = React.lazy(
  () => import('pages/teacherSide/DoSomething/DoSomething'),
);

const TeacherNews = React.lazy(
  () => import('pages/teacherSide/TeacherNews/TeacherNews'),
);

const CommonStudentSelect = React.lazy(
  () =>
    import('pages/teacherSide/common/CommonStudentSelect/CommonStudentSelect'),
);

const CommonTeacherSelect = React.lazy(
  () =>
    import('pages/teacherSide/common/CommonTeacherSelect/CommonTeacherSelect'),
);

const CommonClsSelect = React.lazy(
  () => import('pages/teacherSide/common/CommonClsSelect/CommonClsSelect'),
);

const TEtc = React.lazy(() => import('pages/teacherSide/TEtc/TEtc'));

// att
const WriteAttRecord = React.lazy(
  () => import('pages/teacherSide/attendance/WriteAttRecord/WriteAttRecord'),
);

// chat
const TeacherChatRoomList = React.lazy(
  () =>
    import('pages/teacherSide/chat/TeacherChatRoomList/TeacherChatRoomList'),
);
const TeacherChatRoomContent = React.lazy(
  () =>
    import(
      'pages/teacherSide/chat/TeacherChatRoomContent/TeacherChatRoomContent'
    ),
);

// attendance
const TeacherAttRecordList = React.lazy(
  () =>
    import(
      'pages/teacherSide/attendance/TeacherAttRecordList/TeacherAttRecordList'
    ),
);
// 교사 근태 관리
const AttRecordListOfTeacher = React.lazy(
  () =>
    import(
      'pages/teacherSide/attendance/AttRecordListOfTeacher/AttRecordListOfTeacher'
    ),
);

// 설정관련
const Setting = React.lazy(
  () => import('pages/teacherSide/setting/Setting/Setting'),
);

const SettingAcademyInfo = React.lazy(
  () =>
    import('pages/teacherSide/setting/SettingAcademyInfo/SettingAcademyInfo'),
);

const SettingAttendance = React.lazy(
  () => import('pages/teacherSide/setting/SettingAttendance/SettingAttendance'),
);

const SettingBillingPlan = React.lazy(
  () =>
    import('pages/teacherSide/setting/SettingBillingPlan/SettingBillingPlan'),
);

const SettingMessage = React.lazy(
  () => import('pages/teacherSide/setting/SettingMessage/SettingMessage'),
);

const SettingPost = React.lazy(
  () => import('pages/teacherSide/setting/SettingPost/SettingPost'),
);

const SettingChat = React.lazy(
  () => import('pages/teacherSide/setting/SettingChat/SettingChat'),
);

const SettingHomework = React.lazy(
  () => import('pages/teacherSide/setting/SettingHomework/SettingHomework'),
);

// 숙제, 일정
const TeacherHomework = React.lazy(
  () => import('pages/teacherSide/homework/TeacherHomework/TeacherHomework'),
);

const TeacherHomeworkDetail = React.lazy(
  () =>
    import(
      'pages/teacherSide/homework/TeacherHomeworkDetail/TeacherHomeworkDetail'
    ),
);

const TeacherHomeworkAdd = React.lazy(
  () => import('pages/teacherSide/homework/HomeworkAdd/HomeworkAdd'),
);

// 푸시 관련
const PushList = React.lazy(
  () => import('pages/teacherSide/push/PushList/PushList'),
);

const PushSetting = React.lazy(
  () => import('pages/teacherSide/push/PushSetting/PushSetting'),
);

// 고객센터 관련
const Support = React.lazy(
  () => import('pages/teacherSide/support/Support/Support'),
);

const DummyDataAdd = React.lazy(
  () => import('pages/teacherSide/support/DummyDataAdd/DummyDataAdd'),
);

const NoticeList = React.lazy(
  () => import('pages/teacherSide/support/NoticeList/NoticeList'),
);

const NoticeDetail = React.lazy(
  () => import('pages/teacherSide/support/NoticeDetail/NoticeDetail'),
);

const RouteListTeacherSide = (
  <Route path="teacherSide/">
    <Route path="doSomething" element={<DoSomething />} />
    <Route path="news" element={<TeacherNews />} />
    <Route path="etc" element={<TEtc />} />
    <Route path="common/studentSelect" element={<CommonStudentSelect />} />
    <Route path="common/teacherSelect" element={<CommonTeacherSelect />} />
    <Route path="common/clsSelect" element={<CommonClsSelect />} />

    <Route path="attendance/">
      <Route path="writeAttRecord" element={<WriteAttRecord />} />
      {/* 교사측에서 보는 학생 출결관리라는 뜻이다 */}
      <Route path="student/list" element={<TeacherAttRecordList />} />
      {/* 근태관리 */}
      <Route path="teacher/list" element={<AttRecordListOfTeacher />} />
    </Route>

    <Route path="chat">
      <Route path="" element={<TeacherChatRoomList />} />
      <Route path="room" element={<TeacherChatRoomContent />} />
    </Route>

    <Route path="setting">
      <Route path="" element={<Setting />} />
      <Route path="academyInfo" element={<SettingAcademyInfo />} />
      <Route path="billingPlan" element={<SettingBillingPlan />} />
      <Route path="attendance" element={<SettingAttendance />} />
      <Route path="message" element={<SettingMessage />} />
      <Route path="post" element={<SettingPost />} />
      <Route path="chat" element={<SettingChat />} />
      <Route path="homework" element={<SettingHomework />} />
    </Route>

    <Route path="homework/">
      <Route path="list" element={<TeacherHomework />} />
      <Route path="add" element={<TeacherHomeworkAdd />} />
      <Route
        path="detail/:homeworkGroupId"
        element={<TeacherHomeworkDetail />}
      />
    </Route>

    <Route path="push">
      <Route path="list" element={<PushList />} />
      <Route path="setting" element={<PushSetting />} />
    </Route>

    <Route path="support">
      <Route path="" element={<Support />} />
      <Route path="dummyDataAdd" element={<DummyDataAdd />} />
      <Route path="notice/list" element={<NoticeList />} />
      <Route path="notice/detail/:slug" element={<NoticeDetail />} />
    </Route>
  </Route>
);

export default RouteListTeacherSide;
