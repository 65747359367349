import { EmptyRequestT } from 'types/shared/reqAndRes/CommonRequest';
import { MyResponse } from 'types/shared/reqAndRes/MyResponse';
import { MyInfoT } from 'types/shared/core/User';
import { READ_MY_INFO } from 'network/shared/user';
import { api } from 'utils/api/api';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { StudentT } from 'types/shared/core/Student';
import { AcademySettingT } from 'types/shared/core/Academy';
import { getActiveAcademyId } from './RootStore';
import { pagesWithoutMyInfo } from './pagesWithoutMyInfo';

class UserStore {
  myInfo: MyInfoT | null = null;
  myInfoVersion = '';
  myInfoTimestamp = '';

  constructor() {
    makeAutoObservable(this);

    // 생성과 함께 언제나 실행
    this.loadMyInfo();

    reaction(
      () => this.myInfoVersion,
      () => this.loadMyInfo(),
    );
  }

  setVersion = (v: string) => {
    this.myInfoVersion = v;
  };

  // 자신의 정보 불러오기
  loadMyInfo = async () => {
    if (pagesWithoutMyInfo.includes(window.location.pathname)) {
      console.log('this page does not need MyInfo');
      return;
    }

    try {
      const response = await api<EmptyRequestT, MyResponse<MyInfoT>>(
        READ_MY_INFO,
        {},
      );

      if (response.isSuccess) {
        runInAction(() => {
          this.myInfo = response.object as MyInfoT;
          this.myInfoTimestamp = new Date().toString();
        });
      } else {
        // 나의 정보 읽어오지 못함
        console.log('MY_INFO error');
      }
    } catch (e) {
      // 오류
      console.log('MY_INFO exception', e);
    }
  };

  // 자신과 연결된 학생 정보 얻기
  getStudent = (studentId: number): StudentT | null => {
    if (!this.myInfo) return null;
    if (this.myInfo.userInfo.role !== 'parent') return null;
    if (!this.myInfo.parentInfo?.linkedAcademyList) return null;
    const studentList = this.myInfo.parentInfo.linkedAcademyList
      .map((la) => la.linkedStudents)
      .flat()
      .filter((s) => s.id === studentId);
    if (studentList.length !== 1) return null;
    else return studentList[0];
  };

  get isDirector(): boolean {
    return this.myInfo?.teacherInfo?.teacher.position === 'director';
  }

  get isManager(): boolean {
    return (
      this.myInfo?.teacherInfo?.teacher.position === 'director' ||
      this.myInfo?.teacherInfo?.teacher.position === 'assistantDirector'
    );
  }

  get isAssistantDirector(): boolean {
    return this.myInfo?.teacherInfo?.teacher.position === 'assistantDirector';
  }

  // 운영자&학원비 담당 또는 원장
  get isFinancialManager(): boolean {
    return (
      (this.myInfo?.teacherInfo?.teacher.position === 'assistantDirector' &&
        this.myInfo?.teacherInfo?.teacher.isFinancialManager) ||
      this.myInfo?.teacherInfo?.teacher.position === 'director'
    );
  }

  get isTeacher(): boolean {
    return this.myInfo?.userInfo.role === 'teacher';
  }

  get isParent(): boolean {
    return this.myInfo?.userInfo.role === 'parent';
  }

  get isStudent(): boolean {
    return this.myInfo?.userInfo.role === 'student';
  }

  get activeAcademySetting(): AcademySettingT | null {
    const activeAcademyId = getActiveAcademyId();
    if (!activeAcademyId) return null;

    if (this.isTeacher) {
      const academy = this.myInfo?.teacherInfo?.academy;
      if (!academy) return null;

      return {
        insiderSetting: academy.insiderSetting,
        publicSetting: academy.publicSetting,
      };
    } else if (this.isParent) {
      const filteredAcademyList =
        this.myInfo?.parentInfo?.linkedAcademyList.filter(
          (academy) => academy.academy.id === activeAcademyId,
        );
      if (!filteredAcademyList?.length) return null;

      const academy = filteredAcademyList[0].academy;
      return {
        publicSetting: academy.publicSetting,
      };
    } else if (this.isStudent) {
      const filteredAcademyList =
        this.myInfo?.studentInfo?.linkedAcademyList.filter(
          (academy) => academy.academy.id === activeAcademyId,
        );
      if (!filteredAcademyList?.length) return null;

      const academy = filteredAcademyList[0].academy;
      return {
        publicSetting: academy.publicSetting,
      };
    }
    return null;
  }
}

export default UserStore;
