// node env 등 환경설정 내용을 구조화 해 내보낸다.

const Config = {
  apiBaseUrl: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`,
  fileServerBaseUrl: `${process.env.REACT_APP_FILESERVER_PROTOCOL}://${process.env.REACT_APP_FILESERVER_HOST}:${process.env.REACT_APP_FILESERVER_PORT}`,
  chatServerApiBaseUrl: `${process.env.REACT_APP_CHATSERVER_API_PROTOCOL}://${process.env.REACT_APP_CHATSERVER_HOST}:${process.env.REACT_APP_CHATSERVER_API_PORT}`,
  chatServerSocketioUrl: `${process.env.REACT_APP_CHATSERVER_SOCKETIO_PROTOCOL}://${process.env.REACT_APP_CHATSERVER_SOCKETIO_HOST}:${process.env.REACT_APP_CHATSERVER_SOCKETIO_PORT}`,
  chatServerSocketioPath: `${process.env.REACT_APP_CHATSERVER_SOCKETIO_PATH}`,

  postImagePathPrefix: '/postImage',
  smallPostImagePathPrefix: '/postImage/small',
  postVideoPathPrefix: '/postVideo',
  postVideoThumbnailPathPrefix: '/postVideo/thumbnail',
  postAttachmentPathPrefix: '/postAttachment',

  epayUrl: `${process.env.REACT_APP_EPAY_URL}`,
};

export default Config;
