import React from 'react';
import { Route } from 'react-router-dom';

//
//
//
// management
//
// 1) student
//
const StudentAddEdit = React.lazy(
  () => import('pages/management/StudentAddEdit/StudentAddEdit'),
);
const StudentDetail = React.lazy(
  () => import('pages/management/StudentDetail/StudentDetail'),
);
const StudentList = React.lazy(
  () => import('pages/management/StudentList/StudentList'),
);
//
// 2) teacher
//
const TeacherAddEdit = React.lazy(
  () => import('pages/management/TeacherAddEdit/TeacherAddEdit'),
);
const TeacherDetail = React.lazy(
  () => import('pages/management/TeacherDetail/TeacherDetail'),
);
const TeacherList = React.lazy(
  () => import('pages/management/TeacherList/TeacherList'),
);

//
// 3) cls
//
const ClsAddEdit = React.lazy(
  () => import('pages/management/ClsAddEdit/ClsAddEdit'),
);
const ClsDetail = React.lazy(
  () => import('pages/management/ClsDetail/ClsDetail'),
);
const ClsList = React.lazy(() => import('pages/management/ClsList/ClsList'));
const AssignCls = React.lazy(
  () => import('pages/management/AssignCls/AssignCls'),
);
const ClsAssignStudent = React.lazy(
  () => import('pages/management/ClsAssignStudent/ClsAssignStudent'),
);
const ClsAssignTeacher = React.lazy(
  () => import('pages/management/ClsAssignTeacher/ClsAssignTeacher'),
);

const AcademyDetail = React.lazy(
  () => import('pages/management/AcademyDetail/AcademyDetail'),
);

const InvitationMessage = React.lazy(
  () => import('pages/teacherSide/InvitationMessage/InvitationMessage'),
);

const RouteListTSManagement = (
  <Route path="teacherSide/management/">
    {/* 관리화면 - 학생 */}
    <Route path="student/">
      <Route path="list" element={<StudentList />} />
      <Route path="add" element={<StudentAddEdit mode="add" />} />
      <Route path="edit/:studentId" element={<StudentAddEdit mode="edit" />} />
      <Route path="detail/:studentId" element={<StudentDetail />} />
      <Route path="assignCls" element={<AssignCls target="student" />} />
    </Route>

    {/* 관리화면 - 교사 */}
    <Route path="teacher/">
      <Route path="list" element={<TeacherList />} />
      <Route path="detail/:teacherId" element={<TeacherDetail />} />
      <Route path="assignCls" element={<AssignCls target="teacher" />} />
      <Route path="add" element={<TeacherAddEdit mode="add" />} />
      <Route path="edit/:teacherId" element={<TeacherAddEdit mode="edit" />} />
    </Route>

    {/* 관리화면 - 클래스 */}
    <Route path="cls/">
      <Route path="list" element={<ClsList />} />
      <Route path="detail/:clsId" element={<ClsDetail />} />
      <Route path="add" element={<ClsAddEdit mode="add" />} />
      <Route path="edit/:clsId" element={<ClsAddEdit mode="edit" />} />
      <Route path="assignStudent" element={<ClsAssignStudent />} />
      <Route path="assignTeacher" element={<ClsAssignTeacher />} />
    </Route>

    {/* 관리화면 - 원 */}
    <Route path="academy/">
      <Route path="detail" element={<AcademyDetail />} />
    </Route>

    {/* 초대 */}
    <Route path="invitationMessage" element={<InvitationMessage />} />
  </Route>
);

export default RouteListTSManagement;
