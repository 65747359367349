import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import RouteListUser from './RouteListUser';
import RouteListStudentSide from './RouteListStudentSide';
import RouteListTeacherSide from './RouteListTeacherSide';
import RouteListTSManagement from './RouteListTSManagement';
import RouteListTSPost from './RouteListTSPost';
import RouteListEtc from './RouterListEtc';
import RouteListTSBill from './RouteListTSBill';
import RouteListChatApp from './RouterListChatApp';

function RouteList() {
  return (
    <Suspense fallback={null}>
      {/* fallback={<div>페이지 로딩중</div>}> */}
      <Routes>
        {/* 공통 화면 시작 */}
        {RouteListUser}

        {/* 학생측 화면 시작 */}
        {RouteListStudentSide}

        {/* 학원관리 - /teacherSide/management/ */}
        {RouteListTSManagement}

        {/* 글관리 - /teacherSide/post/ */}
        {RouteListTSPost}

        {/* 학원비 - /teacherSide/bill/ */}
        {RouteListTSBill}

        {/* 교사측 기타 화면 */}
        {RouteListTeacherSide}

        {/* 기타 화면 */}
        {RouteListEtc}

        {/* 채팅앱 */}
        {RouteListChatApp}

        <Route
          element={
            <div>
              <h2>이 페이지는 존재하지 않습니다.</h2>
              {location.pathname}
            </div>
          }
        />
      </Routes>
    </Suspense>
  );
}
export default RouteList;
