import React from 'react';
import { Route } from 'react-router-dom';

//
// 4) 글쓰기
//
const BoardPostWriteEdit = React.lazy(
  () =>
    import(
      'pages/teacherSide/postWriteEdit/BoardPostWriteEdit/BoardPostWriteEdit'
    ),
);
const NotePostWriteEdit = React.lazy(
  () =>
    import(
      'pages/teacherSide/postWriteEdit/NotePostWriteEdit/NotePostWriteEdit'
    ),
);
const PersonalMessage = React.lazy(
  () =>
    import(
      'pages/teacherSide/postWriteEdit/WritePersonalMessage/WritePersonalMessage'
    ),
);
const YoutubeLink = React.lazy(
  () => import('pages/teacherSide/postWriteEdit/YoutubeLink/YoutubeLink'),
);

const WritableBoardList = React.lazy(
  () =>
    import(
      'pages/teacherSide/postWriteEdit/WritableBoardList/WritableBoardList'
    ),
);

const TeacherPostView = React.lazy(
  () => import('pages/teacherSide/postView/TeacherPostView/TeacherPostView'),
);

const ViewPersonalMessage = React.lazy(
  () =>
    import(
      'pages/teacherSide/postView/ViewPersonalMessage/ViewPersonalMessage'
    ),
);

const RouteListTSPost = (
  <Route path="teacherSide/post/">
    {/* 쓰기 */}
    <Route
      path="writeBoardPost"
      element={<BoardPostWriteEdit mode="write" />}
    />
    <Route path="writeNotePost" element={<NotePostWriteEdit mode="write" />} />
    <Route
      path="editBoardPost/:postId"
      element={<BoardPostWriteEdit mode="edit" />}
    />
    <Route
      path="editNotePost/:postId"
      element={<NotePostWriteEdit mode="edit" />}
    />
    {/* 보조화면 */}
    <Route path="writeEdit/writableBoardList" element={<WritableBoardList />} />
    <Route path="writeEdit/personalMessage" element={<PersonalMessage />} />
    <Route path="writeEdit/youtubeLink" element={<YoutubeLink />} />
    {/* 읽기 */}
    <Route
      path="boardPost/:postId"
      element={<TeacherPostView postType="boardPost" />}
    />
    <Route
      path="notePost/:postId"
      element={<TeacherPostView postType="notePost" />}
    />
    <Route
      path="notePost/:postId/personalMessage"
      element={<ViewPersonalMessage />}
    />
  </Route>
);

export default RouteListTSPost;
