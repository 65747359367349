import { AccessTokenPayloadT } from 'types/shared/core/Token';
import Val from 'constants/frontend/shared/Val';
import Token from 'utils/Token';
import { pagesWithoutMyInfo } from './pagesWithoutMyInfo';

// 현재 페이지에 관한 최소한의 정보
class PageStore {
  accessTokenPayload: AccessTokenPayloadT | null;
  activeAcademyId = 0;

  constructor() {
    if (pagesWithoutMyInfo.includes(window.location.pathname)) {
      this.accessTokenPayload = null;
      console.log('this page does not need token');
      return;
    }

    const accessToken = Token.getToken('accessToken');
    this.accessTokenPayload = Token.getAccessTokenPayload(accessToken);

    if (this.accessTokenPayload) {
      if (this.accessTokenPayload.role === 'teacher') {
        const activeAcademyId = this.accessTokenPayload.academyId;
        if (!activeAcademyId) throw new Error('academyId is null');
        this.activeAcademyId = activeAcademyId;
      } else if (
        this.accessTokenPayload.role === 'parent' ||
        this.accessTokenPayload.role === 'student'
      ) {
        const path = window.location.pathname;
        const found = path.match(Val.STUDENT_SIDE_ACADEMY_REGEX);
        let academyId: number | null = null;
        if (found) {
          academyId = parseInt(found[2]);
        }
        this.activeAcademyId = academyId || 0;
        console.log('active academy id = ', this.activeAcademyId);
      }
    } else {
      console.log('no token payload');
    }
  }
}

export default PageStore;
