import { SettingsConsumer, SettingsProvider } from 'contexts/settingsContext';
import { RootProvider } from 'contexts/rootContext';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import RouteList from './routers/RouteList';
import { createTheme } from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { GlobalAuthRouter } from 'routers/GlobalAuthRouter';

const App = (): JSX.Element => {
  return (
    <SettingsProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <RootProvider>
          <SettingsConsumer>
            {({ settings }) => (
              <ThemeProvider
                theme={createTheme({
                  direction: settings.direction,
                  responsiveFontSizes: settings.responsiveFontSizes,
                  mode: 'dark', // settings.theme,
                })}
              >
                <CssBaseline />
                <GlobalAuthRouter />
                <RouteList />
                {/* <AlertContainer/>  컨테이너는 alertstore에 접근을 하고, 각 컴포넌트는 useRootStore.alertStore or useAlertStore  */}
                {/* <SnackbarContainer/>  컨테이너는 alertstore에 접근을 하고, 각 컴포넌트는 useRootStore.alertStore or useAlertStore  */}
                {/* <PopupContainer/>  컨테이너는 alertstore에 접근을 하고, 각 컴포넌트는 useRootStore.alertStore or useAlertStore  */}
              </ThemeProvider>
            )}
          </SettingsConsumer>
        </RootProvider>
      </LocalizationProvider>
    </SettingsProvider>
  );
};

export default App;
