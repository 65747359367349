import { makeAutoObservable } from 'mobx';

class SampleStore {
  constructor() {
    makeAutoObservable(this);
  }

  dummy = 'hello';

  setError = (err) => {
    console.log(err);
  };

  // submit {

  // }

  /*
    formik - yup as validator
    react-hook-form
  */
}

export default SampleStore;
