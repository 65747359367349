import React from 'react';
import { Route } from 'react-router-dom';

//
//
//
// user
//

const Welcome = React.lazy(() => import('pages/user/Welcome/Welcome'));
const Agreement = React.lazy(() => import('pages/user/Agreement/Agreement'));
const AgreementDetail = React.lazy(
  () => import('pages/user/AgreementDetail/AgreementDetail'),
);
const SelectRole = React.lazy(() => import('pages/user/SelectRole/SelectRole'));
const SignIn = React.lazy(() => import('pages/user/SignIn/SignIn'));

const VerifyPhone = React.lazy(
  () => import('pages/user/VerifiyPhone/VerifyPhone'),
);
const VerifyPhoneOfParent = React.lazy(
  () => import('pages/user/VerifiyPhoneOfParent/VerifyPhoneOfParent'),
);

const SignUp = React.lazy(() => import('pages/user/SignUp/SignUp'));

const Onboarding = React.lazy(() => import('pages/user/Onboarding/Onboarding'));

const Setting = React.lazy(() => import('pages/setting/Setting/Setting'));

const SettingAccount = React.lazy(
  () => import('pages/setting/SettingAccount/SettingAccount'),
);

const ProfileEdit = React.lazy(
  () => import('pages/user/ProfileEdit/ProfileEdit'),
);

const MyInfo = React.lazy(() => import('pages/user/MyInfo/MyInfo'));
const MyInfoEdit = React.lazy(() => import('pages/user/MyInfoEdit/MyInfoEdit'));
const Withdrawal = React.lazy(() => import('pages/user/Withdrawal/Withdrawal'));

const RouteListUser = (
  <Route path="user/">
    {/* 공통 화면 시작 */}

    <Route path="welcome" element={<Welcome />} />
    <Route path="agreement" element={<Agreement />} />
    <Route path="agreementDetail" element={<AgreementDetail />} />
    <Route path="selectRole" element={<SelectRole />} />
    <Route path="verifyPhone" element={<VerifyPhone />} />
    <Route path="verifyPhoneOfParent" element={<VerifyPhoneOfParent />} />

    <Route path="hwgi" element={<SignUp />} />
    <Route
      path="onboarding/director"
      element={<Onboarding target="director" />}
    />
    <Route path="onboarding/parent" element={<Onboarding target="parent" />} />
    <Route
      path="onboarding/student"
      element={<Onboarding target="student" />}
    />

    <Route path="signIn" element={<SignIn />} />
    <Route path="setting" element={<Setting />} />
    <Route path="setting/account" element={<SettingAccount />} />
    <Route path="setting/myInfo" element={<MyInfo />} />
    <Route path="setting/myInfo/edit" element={<MyInfoEdit />} />
    <Route path="setting/myInfo/withdrawal" element={<Withdrawal />} />

    <Route path="profileEdit" element={<ProfileEdit />} />
  </Route>
);

export default RouteListUser;
