type Watcher<T> = (newValue: T, oldValue: T) => void;

class ObservableVariable<T> {
  private _value: T;
  private watchers: Array<Watcher<T>>;

  constructor(initialValue: T) {
    this._value = initialValue;
    this.watchers = [];

    return new Proxy(this, {
      get: (target: ObservableVariable<T>, prop: string, receiver: any) => {
        if (prop === 'value') {
          return target._value;
        }
        return (target as any)[prop]; // Ensure other properties/methods are accessed normally
      },
      set: (
        target: ObservableVariable<T>,
        prop: string,
        value: any,
        receiver: any,
      ) => {
        if (prop === 'value') {
          const oldValue = target._value;
          if (oldValue !== value) {
            target._value = value;
            target.watchers.forEach((watcher) => watcher(value, oldValue)); // Notify watchers
          }
          return true; // Indicate success
        }
        (target as any)[prop] = value; // Handle setting other properties normally
        return true;
      },
    });
  }

  addWatcher(watcherFunc: Watcher<T>): void {
    this.watchers.push(watcherFunc);
  }

  removeWatcher(watcherFunc: Watcher<T>): void {
    const index = this.watchers.indexOf(watcherFunc);
    if (index > -1) {
      this.watchers.splice(index, 1);
    }
  }

  // Optionally, you might want to expose a getter for the current value.
  get value(): T {
    return this._value;
  }

  // And a setter if you want to allow setting the value directly,
  // though setting via the proxy is the typical usage.
  set value(v: T) {
    const oldValue = this._value;
    if (oldValue !== v) {
      this._value = v;
      this.watchers.forEach((watcher) => watcher(v, oldValue));
    }
  }
}

export default ObservableVariable;
