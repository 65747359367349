import React from 'react';
import { Route } from 'react-router-dom';

const ChatAppRoot = React.lazy(
  () => import('pages/chatApp/ChatAppRoot/ChatAppRoot'),
);

// 이 페이지들에서는 pageStore가 token을 참조하지 않는다.
// 외부 브라우저에서 바로 표시되어야 하는 페이지들
const RouteListEtc = (
  <Route path="chatAppRoot/" element={<ChatAppRoot />}></Route>
);
export default RouteListEtc;
