import UserStore from 'stores/root/UserStore';
import SampleStore from 'stores/root/SampleStore';
import ClsDataStore from 'stores/shared/ClsDataStore';

import PageStore from './PageStore';
import { RootStoreT } from 'types/frontend/RootStore';
import StudentListCommonStore from 'stores/StudentListCommonStore';
import TeacherListCommonStore from 'stores/TeacherListCommonStore';
import { AcademySettingT } from 'types/shared/core/Academy';

export const updateVersion = (
  version: 'studentList' | 'teacherList' | 'clsList' | 'myInfo',
) => {
  switch (version) {
    case 'studentList':
      if (rootStore.stores.StudentListCommonStore) {
        (
          rootStore.stores.StudentListCommonStore as StudentListCommonStore
        ).setVersion(new Date().toString());
      }
      break;
    case 'teacherList':
      if (rootStore.stores.TeacherListCommonStore) {
        (
          rootStore.stores.TeacherListCommonStore as TeacherListCommonStore
        ).setVersion(new Date().toString());
      }
      break;
    case 'clsList':
      rootStore.clsDataStore.setVersion(new Date().toString());
      break;
    case 'myInfo':
      rootStore.userStore.setVersion(new Date().toString());
  }
};

export const getActiveAcademyId = () => {
  return rootStore.pageStore.activeAcademyId;
};

export const rootStore: RootStoreT = {
  userStore: new UserStore(),
  sampleStore: new SampleStore(),
  clsDataStore: new ClsDataStore(),
  pageStore: new PageStore(),
  stores: {},
};
