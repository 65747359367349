import { AccessTokenPayloadT, TokenObjT } from 'types/shared/core/Token';
import { decodeToken } from 'react-jwt';
import interfaceBridge from './interfaceBridge';
class Token {
  static getAccessTokenPayload(token: string): AccessTokenPayloadT | null {
    if (!token) return null;
    try {
      const decoded = decodeToken<AccessTokenPayloadT>(token);
      if (decoded === null) return null;
      return decoded;
    } catch (e) {
      return null;
    }
  }

  static getToken(kind: 'accessToken' | 'refreshToken') {
    const data = interfaceBridge.getData('CURRENT_USER_ID');
    const currentUserId = parseInt(data);
    if (!currentUserId) return '';

    const tokenObj: TokenObjT | null = interfaceBridge.loadToken(currentUserId);

    if (!tokenObj) return '';

    return tokenObj[kind] || '';
  }

  static getCurrentUserId(): number | null {
    const data = interfaceBridge.getData('CURRENT_USER_ID');
    const currentUserId = parseInt(data);
    if (!currentUserId) return null;
    return currentUserId;
  }

  static setCurrentUserId(id: number) {
    interfaceBridge.setData('CURRENT_USER_ID', id.toString());
  }

  static logout(callback: (() => void) | null) {
    const currentUserId = Token.getCurrentUserId();
    interfaceBridge.setData('CURRENT_USER_ID', '0');
    currentUserId && interfaceBridge.saveToken(currentUserId, null);
    callback && callback();
  }
}

export default Token;
